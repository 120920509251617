<template>
  <q-card class="my-card" flat v-if="onCartDrawer">
    <q-item
      :class="[
        'item-start',
        shoppingCartItem.linkedProducts &&
        shoppingCartItem.linkedProducts.length > 0
          ? 'link-products'
          : '',
      ]"
    >
      <q-item-section avatar>
        <box-image
          :link="{
            name: 'Detail',
            params: { seoName: shoppingCartItem.seoName },
          }"
          :imageSrc="shoppingCartItem.image"
          width="40px"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="product-title ellipsis d">
          <router-link
            :to="{
              name: 'Detail',
              params: { seoName: shoppingCartItem.seoName },
            }"
            v-html="shoppingCartItem.productName"
          />
          <q-tooltip max-width="180px" content-class="bg-dark">
            {{ shoppingCartItem.productName }}
          </q-tooltip>
        </q-item-label>
        <q-item-label class="row no-wrap items-center" caption>
          <AddToCartBtn
            :showMaxErrorOnTop="false"
            :showFullFillment="false"
            :productId="shoppingCartItem.productId"
            :productFulFillments="shoppingCartItem.productFulFillments"
            :cartLineItem="shoppingCartItem"
            :fulFillmentCodeName="fulFillmentCodeName"
            :isinstock="shoppingCartItem.isinstock"
          />
          <q-item-label
            class="q-ml-sm product-qty"
            v-html="`X ${$options.filters.currency(shoppingCartItem.price)}`"
          />
        </q-item-label>
        <!-- <q-item-label class="items-right" caption>
          <span
            class="discount f11 fwnormal bg q-mb-none"
            v-if="shoppingCartItem.discountAmount"
            v-html="
              `You Saved: ${$options.filters.currency(
                Math.abs(shoppingCartItem.discountAmount),
                true
              )}`
            "
          />
        </q-item-label> -->
      </q-item-section>
      <q-item-section class="cartProductSide" side>
        <Trash :orderItemId="shoppingCartItem.orderLineItemID" />
        <!-- <q-item-label
          class="text-strike strike-price q-pt-xs"
          v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
          v-if="shoppingCartItem.finalPrice != shoppingCartItem.totalPrice"
        /> -->
        <q-item-label
          class="product-price"
          v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
        />
      </q-item-section>
      <q-item-section
        class="linked-product bg-primary"
        v-if="
          shoppingCartItem.linkedProducts &&
          shoppingCartItem.linkedProducts.length > 0
        "
      >
        <q-item-label>
          <!-- <q-badge color="white" text-color="primary" class="inline-block">
            Linked Product
          </q-badge> -->
          <q-item
            class="item-start"
            v-for="(linkedProduct, index) in shoppingCartItem.linkedProducts"
            :key="`${linkedProduct.orderLineItemID}${index}`"
          >
            <q-item-section avatar>
              <box-image
                :link="
                  linkedProduct.isSellable
                    ? {
                        name: 'Detail',
                        params: { seoName: linkedProduct.seoName },
                      }
                    : ''
                "
                :imageSrc="linkedProduct.image"
                width="40px"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label class="product-title ellipsis d">
                <router-link
                  v-if="linkedProduct.isSellable"
                  :to="{
                    name: 'Detail',
                    params: { seoName: linkedProduct.seoName },
                  }"
                  v-html="linkedProduct.productName"
                />
                <template v-else>
                  {{ linkedProduct.productName }}
                </template>
                <q-tooltip max-width="180px" content-class="bg-dark">
                  {{ linkedProduct.productName }}
                </q-tooltip>
              </q-item-label>
              <q-item-label class="row no-wrap items-center" caption>
                <q-item-label
                  class="product-qty"
                  v-html="
                    `${linkedProduct.qty} X ${$options.filters.currency(
                      linkedProduct.price
                    )}`
                  "
                />
              </q-item-label>
              <!-- <q-item-label class="items-right" caption>
                <span
                  class="discount f11 fwnormal bg q-mb-none"
                  v-if="linkedProduct.discountAmount"
                  v-html="
                    `You Saved: ${$options.filters.currency(
                      linkedProduct.discountAmount,
                      true
                    )}`
                  "
                />
              </q-item-label> -->
            </q-item-section>
            <q-item-section class="cartProductSide" side>
              <q-item-label
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="$options.filters.currency(linkedProduct.totalPrice)"
                v-if="linkedProduct.totalPrice != linkedProduct.finalPrice"
              />
              <q-item-label
                class="product-price"
                v-html="$options.filters.currency(linkedProduct.finalPrice)"
              />
            </q-item-section>
          </q-item>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
  <div v-else class="cart-order">
    <!-- Mobile View -->
    <div class="cart-product-left">
      <template
        v-if="
          businessConfigurations.enableSubstitution &&
          fulFillmentCodeName !== 'sd'
        "
      >
        <q-checkbox
          color="secondaryOnBody"
          :value="
            substitute(
              shoppingCartItem.orderLineItemID,
              shoppingCartItem.allowSubstitute
            )
          "
          @input="
            (val) => {
              OnSubstituteChange(val, shoppingCartItem.allowSubstitute, {
                productID: shoppingCartItem.productId,
                fulfillmentMethodID: fulFillmentId,
                orderLineItemID: shoppingCartItem.orderLineItemID,
              })
            }
          "
        />
      </template>
      <Trash :orderItemId="shoppingCartItem.orderLineItemID" v-if="isMobile" />
    </div>
    <!-- Mobile View -->

    <!-- Common View -->
    <div
      class="cart-product"
      :class="[
        shoppingCartItem.linkedProducts &&
        shoppingCartItem.linkedProducts.length > 0
          ? 'substitution-product'
          : (substitute(
              shoppingCartItem.orderLineItemID,
              shoppingCartItem.allowSubstitute
            ) &&
              shoppingCartItem.substituteProductDetails &&
              shoppingCartItem.substituteProductDetails.length) ||
            substituteChildProductDetails(
              shoppingCartItem.orderLineItemID,
              shoppingCartItem.allowSubstitute
            ).length
          ? 'substitution-product'
          : '',
        fulFillmentCodeName == 'sd' ? 'sd-spacing' : '',
      ]"
    >
      <!-- Cart Item -->
      <div class="cart-produt-data">
        <div class="produt-data flex items-start">
          <div class="product-image">
            <box-image
              :link="{
                name: 'Detail',
                params: { seoName: shoppingCartItem.seoName },
              }"
              :imageSrc="shoppingCartItem.image"
              :width="isMobile ? '80px': '86px'"
            />
          </div>
          <div class="co-product-details">
            <div class="product--title">
              <h3 class="ellipsis">
                <router-link
                  :to="{
                    name: 'Detail',
                    params: { seoName: shoppingCartItem.seoName },
                  }"
                  v-html="shoppingCartItem.productName"
                />
              </h3>
              <OrderLevelinstruction
                :productId="shoppingCartItem.productId"
                :productFulFillments="shoppingCartItem.productFulFillments"
                :cartLineItem="shoppingCartItem"
                :isfromEditOrderLevel="false"
              />
            </div>

            <div class="price">
              <span
                class="original-price"
                v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
                v-if="
                  shoppingCartItem.finalPrice &&
                  shoppingCartItem.totalPrice != shoppingCartItem.finalPrice
                "
              />
              <span
                :class="
                  shoppingCartItem.finalPrice &&
                  shoppingCartItem.totalPrice != shoppingCartItem.finalPrice
                    ? 'discount-price'
                    : ''
                "
                v-html="
                  $options.filters.currency(
                    shoppingCartItem.finalPrice || shoppingCartItem.totalPrice
                  )
                "
              />
            </div>

            <div class="flex product-edit--options" v-if="!isMobile">
              <div class="product-quantity">
                <AddToCartBtn
                  :showMaxErrorOnTop="false"
                  :productId="shoppingCartItem.productId"
                  :productFulFillments="shoppingCartItem.productFulFillments"
                  :cartLineItem="shoppingCartItem"
                  :fulFillmentCodeName="fulFillmentCodeName"
                  :isinstock="shoppingCartItem.isinstock"
                  ref="lineItemBtn"
                />
                <span class="other-options">
                  x {{ shoppingCartItem.price | currency }}
                  <span
                    v-if="false"
                    class="text-strike"
                    v-html="
                      $options.filters.currency(shoppingCartItem.compareAtPrice)
                    "
                  />
                </span>
              </div>
              <div class="product-delete">
                <Trash
                  :orderItemId="shoppingCartItem.orderLineItemID"
                  :isText="true"
                />
              </div>
            </div>
          </div>
          <div class="flex product-edit--options"  v-if="isMobile">
            <div class="product-quantity">
              <AddToCartBtn
                :showMaxErrorOnTop="false"
                :productId="shoppingCartItem.productId"
                :productFulFillments="shoppingCartItem.productFulFillments"
                :cartLineItem="shoppingCartItem"
                :fulFillmentCodeName="fulFillmentCodeName"
                :isinstock="shoppingCartItem.isinstock"
                ref="lineItemBtn"
              />
              <span class="other-options">
                x {{ shoppingCartItem.price | currency }}
                <span
                  v-if="false"
                  class="text-strike"
                  v-html="
                    $options.filters.currency(shoppingCartItem.compareAtPrice)
                  "
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Cart Item -->
      <!-- Linked Item -->
      <div
        class="substitution-product--block"
        v-if="
          shoppingCartItem.linkedProducts &&
          shoppingCartItem.linkedProducts.length > 0
        "
      >
        <div
          v-for="(linkedProduct, index) in shoppingCartItem.linkedProducts"
          :key="`${linkedProduct.orderLineItemID}${index}`"
          class="substitution-product produt-data flex items-center"
        >
          <div class="product-image">
            <q-avatar square>
              <box-image
                :link="
                  linkedProduct.isSellable
                    ? {
                        name: 'Detail',
                        params: { seoName: linkedProduct.seoName },
                      }
                    : ''
                "
                :imageSrc="linkedProduct.image"
                width="40px"
              />
            </q-avatar>
            <q-avatar flat>
              <SvgIcon icon="linked" />
            </q-avatar>
          </div>
          <div class="co-product-details">
            <div class="product--title">
              <h4>
                <router-link
                  v-if="linkedProduct.isSellable"
                  :to="{
                    name: 'Detail',
                    params: { seoName: linkedProduct.seoName },
                  }"
                  v-html="linkedProduct.productName"
                />
                <template v-else>
                  {{ linkedProduct.productName }}
                </template>
                <q-tooltip max-width="180px" content-class="bg-dark">
                  {{ linkedProduct.productName }}
                </q-tooltip>
              </h4>
            </div>
            <div class="price">
              <span
                class="original-price"
                v-html="$options.filters.currency(linkedProduct.totalPrice)"
                v-if="
                  linkedProduct.finalPrice &&
                  linkedProduct.totalPrice != linkedProduct.finalPrice
                "
              />
              <span
                :class="
                  linkedProduct.finalPrice &&
                  linkedProduct.totalPrice != linkedProduct.finalPrice
                    ? 'discount-price'
                    : ''
                "
                v-html="
                  $options.filters.currency(
                    linkedProduct.finalPrice || linkedProduct.totalPrice
                  )
                "
              />
            </div>
            <div class="flex product-edit--options">
              <div class="product-quantity">
                <span
                  v-html="
                    `${linkedProduct.qty} X ${$options.filters.currency(
                      linkedProduct.price
                    )}`
                  "
              /></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Linked Item -->
      <!-- Substitute Item -->
      <div
        v-if="
          (fulFillmentCodeName !== 'sd' &&
            substitute(
              shoppingCartItem.orderLineItemID,
              shoppingCartItem.allowSubstitute
            ) &&
            shoppingCartItem.substituteProductDetails &&
            shoppingCartItem.substituteProductDetails.length) ||
          substituteChildProductDetails(
            shoppingCartItem.orderLineItemID,
            shoppingCartItem.allowSubstitute
          ).length
        "
        class="substitution-product--sibling"
      >
        <div class="division">
          <span>Choose your preferred Substitution</span> </div
        ><!--
        <template>
          <q-radio dense v-model="radioTrue" val="line" label="No Preference" />
        </template> -->
        <div class="row q-col-gutter-sm substitution-product--items">
          <div
            v-for="(substituteProduct, index) in substituteProductList"
            :key="`${substituteProduct.productID}${index}`"
            class="col-12"
            :class="
              substituteProduct.productName == 'No Preference'
                ? 'col-md-12 order-first'
                : 'col-md-6'
            "
          >
            <div class="produt-data flex items-start">
              <template>
                <q-radio
                  dense
                  color="secondaryOnBody"
                  v-if="fulFillmentCodeName !== 'sd'"
                  :val="
                    selectedChildSubstituteProduct(
                      shoppingCartItem.orderLineItemID
                    ) ||
                    shoppingCartItem.selectedSubstituteProductId ||
                    (substituteProduct.substituteProductID ==
                    '00000000-0000-0000-0000-000000000000'
                      ? substituteProduct.substituteProductID
                      : '')
                  "
                  :value="substituteProduct.substituteProductID"
                  @input="
                    () => {
                      toggleSubstituteChildProduct({
                        orderLineItemID: shoppingCartItem.orderLineItemID,
                        allowSubstitute: shoppingCartItem.allowSubstitute,
                        substituteProductID:
                          substituteProduct.substituteProductID,
                      })
                    }
                  "
                />
              </template>
              <div
                class="product-image"
                v-if="substituteProduct.productName != 'No Preference'"
              >
                <q-avatar square>
                  <box-image
                    :link="{
                      name: 'Detail',
                      params: { seoName: substituteProduct.seoName },
                    }"
                    :imageSrc="substituteProduct.image | defaultProductImage"
                    width="45px"
                  />
                </q-avatar>
              </div>
              <div
                class="co-product-details"
                v-if="substituteProduct.productName != 'No Preference'"
              >
                <div class="product--title">
                  <h5>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: { seoName: substituteProduct.seoName },
                      }"
                      v-html="substituteProduct.productName"
                    />
                  </h5>
                </div>
                <div
                  class="price"
                  v-html="$options.filters.currency(substituteProduct.price)"
                />
              </div>
              <div class="co-product-details" v-else>
                <div class="product--title">
                  <h5>
                    {{ substituteProduct.productName }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Substitute Item -->
    </div>
    <!-- Common View -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AddToCartBtn from '../AddToCartBtn'
import Trash from './Trash'
import { hideShowFullSpinner } from 'src/utils'
import OrderLevelinstruction from '../OrderLevelinstruction'

export default {
  props: {
    shoppingCartItem: {
      type: Object,
      default: null,
    },
    fulFillmentId: {
      type: String,
      default: '',
    },
    onCartDrawer: {
      type: Boolean,
      default: false,
    },
    fulFillmentCodeName: {
      type: String,
      default: '',
    },
    toggleSubstitute: {
      type: Function,
      default: () => {},
    },
    substitute: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AddToCartBtn,
    Trash,
    OrderLevelinstruction,
  },
  computed: {
    ...mapGetters('cart', [
      'substituteChildProductDetails',
      'selectedChildSubstituteProduct',
    ]),
    substituteProductList() {
      let data = []
      if (this.shoppingCartItem) {
        let data = this.substituteChildProductDetails(
          this.shoppingCartItem.orderLineItemID,
          this.shoppingCartItem.allowSubstitute
        )
        return data.length
          ? data
          : this.shoppingCartItem.substituteProductDetails
      }
      return data
    },
  },
  methods: {
    ...mapActions('cart', ['toggleSubstituteChildProduct']),
    OnSubstituteChange: async function (isChecked, allowSubstitute, params) {
      if (isChecked) {
        hideShowFullSpinner(true)
        await this.$store
          .dispatch('cart/getAllSubstituteItemsForProduct', [params])
          .then((response) => {
            if (response.success) {
              let noPreferenceOption = {
                image: '',
                price: 0,
                productID: '00000000-0000-0000-0000-000000000000',
                productName: 'No Preference',
                qtyOnHand: 0,
                seoName: '',
                substituteProductID: '00000000-0000-0000-0000-000000000000',
              }
              let substituteProducts =
                Array.isArray(response.data) && response.data.length
                  ? [
                      ...response.data[0].substituteProductDetails,
                      noPreferenceOption,
                    ]
                  : []
              this.toggleSubstitute({
                orderLineItemId: params.orderLineItemID,
                allowSubstitute: allowSubstitute,
                substituteProducts: substituteProducts,
              })
            } else {
              this.showError(response.message)
            }
          })
          .finally(() => {
            hideShowFullSpinner(false)
          })
      } else {
        this.toggleSubstitute({
          orderLineItemId: params.orderLineItemID,
          allowSubstitute: allowSubstitute,
          substituteProducts: [],
        })
      }
    },
  },
}
</script>
<style lang="scss">
.cartItemList {
  .cartsubstitute {
    position: relative;
    margin-left: 32px;
    padding-top: 15px;
    border: 1px dashed $primary;
    border-radius: 7px;
    margin-top: 15px;
    .cartsubstitute-title {
      position: absolute;
      top: -10px;
      left: 5px;
      padding: 4px 15px;
      background: #ebebeb;
      border-radius: 7px;
      line-height: 1;
      font-size: 14px;
    }
    .cartItem {
      padding: 10px 0;
      @media (max-width: 1024px) {
        border-top: none;
        .cartItemDet {
          padding-right: 15px;
          padding-bottom: 0px;
          min-height: auto;
          align-self: center;
        }
        .cartItemDesc {
          padding-bottom: 0;
        }
      }
      .cartItemPrice {
        text-align: left;
        margin-top: 5px;
        font-size: 16px;
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
